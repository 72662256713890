import React, { Component } from 'react';

import http from 'Demora/http';

export default function asyncComponent(getComponent) {
	class AsyncComponent extends Component {
		static Component = null;

		state = {
			Component: AsyncComponent.Component,
			attempts: 0,
		};

		componentDidMount() {
			this.getComponent();
		}

		getComponent = () => {
			const state = this.state;

			if (!state.Component) {
				getComponent()
					.then((module) => module.default)
					.then((Component) => {
						AsyncComponent.Component = Component;

						this.setState({ Component });
					})
					.catch((error) => {
						const attempts = state.attempts;

						if (attempts == 10) {
							http.get('/data/start').then((data) => {
								if (data.rufP) {
									throw error;
									return;
								}
							});

							return;
						}

						this.setState({
							attempts: attempts + 1,
						});

						setTimeout(this.getComponent, state.attempts * 1000);
					});
			}
		};

		render() {
			const { Component } = this.state;

			if (Component) {
				return <Component {...this.props} />;
			}

			return null;
		}
	}

	return AsyncComponent;
}
