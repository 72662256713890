let config = {
	addons: [],

	dataDomain:
		typeof document === 'undefined'
			? ''
			: document.location.port == 8080
			? 'http://webinars.inventorybase.test:8000'
			: '',
};

export default config;
