import React, { Component } from 'react';

import { NavbarBrand } from 'reactstrap';

import ib_logo from '../../images/ib/logo.png';

import pi_logo from '../../images/pi/logo.png';

class TopNavBarLogo extends Component {
	render() {
		const domain = document.location.toString().split('/')[2];
		return (
			<NavbarBrand>
				<img src={domain.match(/inventorybase/)?ib_logo:pi_logo} style={{ height: '30px' }} />
			</NavbarBrand>
		);
	}
}

export default TopNavBarLogo;
