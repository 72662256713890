import asyncComponent from 'Demora/AsyncComponent';

export default [
	{
		path: '/add_prospect/:sales/:show',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "webcast_register" */ '../../../js/AddProspectToAgile'
			)
		),
	},
	{
		path: '/reports/23402-2341123sdfwegwegwg',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "webcast_register" */ '../../../js/Reports/'
			)
		),
	},
//	{
//		path: '/reports/23402-2gerg34gghrggrg',
//		component: asyncComponent(() =>
//			import(
//				/* webpackChunkName: "webcast_register" */ '../../../js/Reports/total_sales'
//			)
//		),
//	},
	{
		path: '/webcast/register/:webcastId/:webattendeeId?',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "webcast_register" */ '../../../js/Register'
			)
		),
	},
	{
		path: '/webcast/view/:attendeeId/a',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "webcast_view" */ '../../../js/View')
		),
	},

];
